@import "../settings/colors.scss";

.project{
  margin: 10px auto;
  text-align: center;
}

.projects_images{
  padding: 10px;
  width: 300px;
  border-radius: 20px;
  //box-shadow: 0 0 10px 0px rgba(255, 255, 255, 1);
}

.projects_icons{
  width: 30px;
  margin: 0 5px;
}

.code_button{
  border: 0;
  border-radius: 10px;
  background: linear-gradient(342deg, rgba(111,43,255,1) 36%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 100%);
  color: $text-color;
  padding: 3px 15px;
}

.code_icon{
  vertical-align: -2px;
  margin-right: 5px;
}

.link{
  color: white;
  text-decoration: none;
  &:hover{
    color: white;
  }
}

@media screen and (max-width: 992px){
  .projects_icons{
    width: 20px;
  }
}

@media screen and (max-width: 800px){
  .projects_images{
    width: 100%;
  }
}

@media screen and (max-width: 470px){
  .code_button{
    font-size: 12px;
    padding: 3px 10px;
  }
  .code_icon{
    width: 12px;
  }

  .projects_icons{
    width: 16px;
  }
}