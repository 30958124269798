@import "../settings/colors.scss";

.menu_btn{
  font-size: 16px;
  text-decoration: none;
  padding-left: 15px;
  font-weight: bold;
}

.menu_btn_active{
  color: $menu_btn_active;
  @extend .menu_btn;
}

.menu_btn_inactive{
  color: $menu_btn_inactive;
  @extend .menu_btn;
}

.header_layout{
  z-index: 999;
  padding: 10px 30px;
  margin-top: 10px;
}

.portfolio{
  text-align: center;
}

.links{
  text-align: center;
  margin-right: 30px;
}

.icons_container{
  text-align: center;
}

.menu_icons{
  margin: 0 10px;
  font-size: 24px;
  color: $text-color;
}

.portfolioIcon{
  margin-right: 3px;
}

.menu_btn_active, .menu_btn_inactive, .portfolioIcon, .menu_icons{
  transition: 0.3s;
  &:hover{
    transition: 0.3s;
    color: $text-color;
  }
}

@media screen and (max-width: 992px){
  .links{
    margin-top: 20px;
  }
}