@import "../settings/colors.scss";

.container{
  margin-top: 30px;
  padding: 20px 0;
  width: 55%;
}

.section{
  margin-top: 50px;
}

.section_hr{
  width: 160px;
  margin-top: 0;
  color: $text-color;
}

.about_page_container{
  margin-top: -20px;
}

@media screen and (max-width: 1200px){
  .container{
    width: 90%;
  }
}

@media screen and (max-width: 1000px){
  .about_page_container{
    margin-top: -40px;
  }
}

@media screen and (max-width: 768px){
  .container{
    width: 96%;
  }
}