@import "../settings/colors.scss";

.imageBlock {
  background: #0d0d11;
}

.imageBlock{
  height: 400px;
  width: 100%;
  margin-top: 10px;
}

.imageBlockContent{
  position: relative;
  top: 35%;
  margin-right: 50px;
}

.pageTitle{
  font-weight: bold;
  text-align: right;
}

.pageDescription{
  color: $light-gray;
  text-align: right;
}

.visitWebsite{
  float: right;
  margin-top: 30px;
  background: $purple-color-low-opacity;
  color: $light-purple-color;
  border: 2px solid $light-purple-color;
  padding: 5px 30px;
  border-radius: 15px;
}

.visitIcon{
  vertical-align: -2px;
  font-size: 15px;
  margin-right: 5px;
}

.bigTitle{
  text-align: center;
  margin-top: 30px;
}

.smallTitle{
  margin-top: 20px !important;
}

.centerRow{
  text-align: center;
}

.technologies_icons_row{
  text-align: center;
}

.technologies_icons_col{
  display: inline-block;
  margin: 0 10px;
}

.technologies_icons{
  width: 60px;
  padding: 10px;
}

.big_icon{
  width: 55px;
}

.reviewText{
  padding: 25px 20px;
}

.reviewImage{
  width: 100%;
  padding: 20px;
  border-radius: 40px;
}

.link{
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
}

@media screen and (max-width: 800px){
  .pageTitle{
    text-align: center;
  }
  .pageDescription{
    text-align: center;
  }
  .visitWebsite {
    float: none;
    display: block;
    margin: 30px auto 0 auto;
  }
  .imageBlockContent{
    margin-right: 0px;
  }
}

@media screen and (max-width: 991px){
  .reviewImage{
    margin: -40px 0 10px 0;
  }
}