.container{
  padding: 20px 30px;
  display: block;
  margin: auto;
}

.job_icons{
  width: 35px;
  display: inline-block;
  margin: 0 10px;
}