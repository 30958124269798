@import "../settings/colors.scss";

.title{
  cursor: pointer;
  transition: 0.3s;
  &:hover{
    transition: 1s;
    background-image: linear-gradient(342deg, rgba(111,43,255,1) 60%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 95%);
    background-clip: text;
    color: transparent;
  }
}

.description{
  color: $description-color;
}

h3{
  font-family: "bold", sans-serif;
}

.aboutButton{
  background: linear-gradient(342deg, rgba(111,43,255,1) 36%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 100%);
  border: $purple-color;
  color: $text-color;
  border-radius: 12px;
  padding: 5px 15px;
  margin-top: 10px;

  //&:hover{
  //  transition: 1s;
  //  background: linear-gradient(342deg, rgba(111,43,255,1) 59%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 100%);
  //}
}

hr{
  background-image: linear-gradient(90deg, $purple-blue-color, transparent);
  border: 0;
  height: 5px;
}

.technologies_hr{
  width: 230px;
  margin-top: 0;
  color: $text-color;
}

h5{
  text-align: center;
}

.link{
  color: $text-color;
  text-decoration: none;

  &:hover{
    color: $text-color;
  }
}

@media screen and (max-width: 700px){
  .technologies_icons{
    width: 55px;
  }
}

@media screen and (max-width: 700px){
  .technologies_icons{
    width: 40px;
    padding: 3px;
  }
}

@media screen and (max-width: 570px){
  .technologies_icons{
    width: 30px;
    padding: 1px;
  }
}