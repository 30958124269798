@import "colors.scss";

@font-face {
  font-family: 'bold';
  src: url(../../../public/fonts/Raleway-Bold.ttf);
}

@font-face {
  font-family: 'medium';
  src: url(../../../public/fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: 'light';
  src: url(../../../public/fonts/Raleway-Light.ttf);
}

body{
  font-family: "medium", sans-serif;
  background: $background;
  color: $text-color;
  cursor: pointer;
}