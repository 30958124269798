@import "../settings/colors.scss";

.technologies {
  margin-top: 30px;
}

.technologies_icons{
  width: 60px;
  padding: 10px;
  margin: auto;
  display: block;
}

.vertical_line_right{
  border-right: 6px solid green;
  height: 200px;
}

.vertical_line_left{
  border-left: 6px solid green;
  height: 200px;
}

@media screen and (max-width: 460px){
  .technologies_icons{
    width: 30px;
    padding: 2px;
  }
}