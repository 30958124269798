@import "../settings/colors.scss";

.button{
  border: none;
  border-radius: 15px;
  padding: 3px 10px;
}

.progress{
  background: $progress-background;
  color: $progress-color;
}

.completed{
  background: $completed-background;
  color: $completed-color;
}

.personalProject{
  background: $personal-project-background;
  color: $personal-project-color;
}

.workProject{
  background: $work-project-background;
  color: $work-project-color;
}

.hackathonProject{
  background: $hackathon-project-background;
  color: $hackathon-project-color;
}

@media screen and (max-width: 768px){
  .button{
    font-size: 12px;
  }
}

@media screen and (max-width: 575px){
  .button{
    margin-bottom: 10px;
  }
}